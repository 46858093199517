import HttpClient from "../common/lib/httpClient";
import { ApiPagination, ApiQuery } from "../common/types/sharedTypes";

import {
  QRECreateParams,
  QREDetails,
  QREEditParams,
  QREItem,
  QREList,
} from "./QRE.types";

const getList = async (
  params: ApiPagination & ApiQuery & { creator?: string; status?: string }
): Promise<QREList> => {
  return HttpClient.get(`/qre`, params);
};

const createQRE = async (qre: QRECreateParams): Promise<QREDetails> => {
  return HttpClient.post(`/qre`, qre);
};

const getOne = async (id: string): Promise<QREItem> => {
  return HttpClient.get(`/qre/${id}`);
};

const edit = async (qre: QREEditParams): Promise<QREDetails> => {
  return HttpClient.put(`/qre/${qre.id}`, qre);
};

export default {
  getOne,
  getList,
  createQRE,
  edit,
};
